import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { DraftTable } from '../../components/tables';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = ['_id', '__v', 'featureImage', 'graduationRate', 'accreditation', 'typeOfCourses', 'affiliateLinkId', 'programs', 'createdBy', 'updatedBy', 'post'];

const DraftPost = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [posts, fetchPosts] = useAxios('', [], currentuser.data.token, 'get');

  const postID = props.match.params.postId;
  useEffect(() => {
    fetchPosts(`${SERVER_URL}/draftpost/${postID}`, []);
  }, [fetchPosts, postID]);

  const revisionHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/draftpost/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Post is inserted.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/posts');
    } catch (err) {
      notification.error({
        message: 'Problem with revert. Please try later.',
        placement: 'bottomRight',
      });
    }

  };

  let columnKeys;
  if (posts.data && posts.data && posts.data.length > 0) {
    const keys = Object.keys(posts.data[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }



  let tableData = [];
  if (posts.data && posts.data.length > 0) {
    tableData = posts.data.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      if (item.title && item.title.en) {
        if (item.title.en) {
          item.title = item.title.en;
        }
      }
      return item;
    });
  }
  return (
    <div className='table'>
      <div className='actions-block'>
        <Link to='/admin/posts'>
          <Button type='primary'>All posts</Button>
        </Link>
      </div>

      <div style={{ textAlign: 'center' }}>
        {posts.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!posts.isLoading && posts.data && posts.data.length > 0 && (
          <DraftTable data={tableData} revisionHandler={revisionHandler} columnKeys={columnKeys} title='Post' />
        )}
        {!posts.isLoading && posts.data && posts.data && posts.data.length === 0 &&
          <h2>NO DATA</h2>}
      </div>
    </div>
  );
};

export default DraftPost;
