import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../App';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/TagTable';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = ['_id', '__v', 'description', 'image', 'user', 'url', 'tag', 'post'];

const Tags = () => {
  const currentuser = useContext(UserContext);
  const [tag, fetchTag] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    fetchTag(`${SERVER_URL}/tags`, []);
  }, [fetchTag]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/tags/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
      });
      history.push('/admin');
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  if (tag.data && tag.data.items && tag.data.items.length > 0) {
    const keys = Object.keys(tag.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (tag.data && tag.data.items && tag.data.items.length > 0) {
    tableData = tag.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      item.user = item?.createdBy?.email;
      return item;
    });
  }

  return (
    <div className='table'>
      <div className='actions-block'>
        <Link to='/admin/new-tag'>
          <Button type='primary'>New tag</Button>
        </Link>
      </div>

      <div style={{ textAlign: 'center' }}>
        {tag.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!tag.isLoading && tag.data && tag.data.items && tag.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteDataHandler}
            columnKeys={columnKeys}
            title='Tags'
            editPath='/admin/edit-tag/'
          />
        )}
        {!tag.isLoading && tag.data && tag.data.items && tag.data.items.length === 0 && <h2>NO DATA</h2>}
      </div>
    </div>
  );
};

export default Tags;
