import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, notification, Modal, Form, Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
//import { GalleryTable } from '../../components/tables';
import { Row, Col } from 'antd';
import Axios from 'axios';
import GalleryImage from '../../components/base/GalleryImage';

const { TextArea } = Input;

// const TABLE_COLUMN_KEYS = ['_id', '__v', 'key', 'updatedAt', 'uploadedBy', 'url', 'forMobile', 'localPath'];

const Gallery = (props) => {
  const currentuser = useContext(UserContext);
  const [form] = Form.useForm();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [altTitle, setAltTitle] = useState('');
  const [fetchTrigger, setFetchTrigger] = useState(false);

  const [galleryForDelete, setGalleryForDelete] = useState([]);
  const [selected, setSelected] = useState([]);
  const [currentSelected, setCurrentSelected] = useState([]);
  const [images, setImages] = useState(null);
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1, id: null });
  const [allSelected, setAllSelected] = useState(false);
  const [search, setSearch] = useState('');
  //const [visible, setVisible] = useState(false);
  const { id } = props.match.params;

  useEffect(() => {
    try {
      const fetchImages = async () => {
        const imagesRes = await Axios.get(`${SERVER_URL}/images`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        setImages(imagesRes);
      };
      fetchImages();
    } catch (error) {
      console.log(error);
    }
    //setModal((modal) => ({ ...modal, limit: 20 })); // setting to 20 for testing selectHandler
  }, [fetchTrigger, id, title, currentuser.data.token]);

  const editImageTrigger = (id, title, description, altTitle) => {
    setTitle(title);
    setDescription(description);
    setAltTitle(altTitle);
    setModal({ ...modal, visible: true, formKey: 'featureImage', id: id });
  };

  const selectHandler = (id) => {
    let arr = [];
    if (selected.includes(id)) {
      const removed = selected.filter((item) => item !== id);
      setSelected(removed);
      setGalleryForDelete(removed);
      setAllSelected(false);
    }
    if (!selected.includes(id)) {
      setSelected([...selected, id]);
      arr.push(id);
      setGalleryForDelete([...selected, arr]);
      setAllSelected(false);
    }
  };

  // const selectAll = () => {
  //   let arr = [];
  //   images.data.map((item) => {
  //     arr.push(item);
  //   });
  //   setAllSelected(!allSelected);
  //   if (!allSelected) {
  //     setSelected(arr);
  //     setGalleryForDelete(arr);
  //   } else {
  //     setSelected([]);
  //     setGalleryForDelete([]);
  //   }
  // };

  // LOGIKA ZA BRISANJE SVIH SLIKA IZ BAZE I IZ s3 NIJE DOVRSENA
  const galeryDelete = async () => {
    if (galleryForDelete.length > 0) {
      const selectedImages = { images: galleryForDelete };

      try {
        await Axios.post(`${SERVER_URL}/images-delete`, selectedImages, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        setGalleryForDelete([]);
        notification.success({
          message: 'Images deleted.',
          placement: 'bottomRight',
        });
        setFetchTrigger(!fetchTrigger);
      } catch (err) {
        notification.error({
          message: 'Problem with deleting images. Please try later.',
          placement: 'bottomRight',
        });
      }
    }
  };

  // On click push id of the image
  const deleteBucket = (id) => {
    let arr = [];
    arr.push(id);
    setGalleryForDelete(arr);
  };

  // DELETE one image
  const deleteOneImage = async (id) => {
    deleteBucket(id);
    const selectedImages = { images: galleryForDelete };

    try {
      await Axios.post(`${SERVER_URL}/images-delete/${id}`, selectedImages, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setGalleryForDelete([]);
      notification.success({
        message: 'Image deleted.',
        placement: 'bottomRight',
      });
      setFetchTrigger(!fetchTrigger);
    } catch (err) {
      notification.error({
        message: 'Problem with deleting the image. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  // onEdit
  const okHandle = async (id) => {
    const update = {
      updatedImage: {
        title,
        description,
        altTitle,
      },
    };
    try {
      await Axios.put(`${SERVER_URL}/images-update/${id}`, update.updatedImage, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setModal({ visible: false });
      notification.success({
        message: 'Image edited.',
        placement: 'bottomRight',
      });
      setFetchTrigger(!fetchTrigger);
    } catch (error) {
      notification.error({
        message: 'Problem with editing the image. Please try later.',
        placement: 'bottomRight',
      });
    }
  };
  return (
    <div className='edit-panel'>
      <div className='actions-block'>
        <Link to='/admin/gallery/new-content'>
          <Button type='primary'>Add new content</Button>
        </Link>
        <Button
          style={{ marginLeft: '8px' }}
          onClick={() => {
            galeryDelete();
          }}
          type='primary'
        >
          Delete selected
        </Button>

        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{ width: '250px', marginLeft: '450px' }}
          placeholder='Search by filename'
        />

        {/* <Button type='primary' style={{ marginLeft: '8px' }} onClick={selectAll}>
          {!allSelected ? 'Select all' : 'Deselect all'}
        </Button> */}
      </div>

      <div className='content' style={{ padding: '15px' }}>
        {/* SEARCH */}
        {images && search && images?.data && (
          <Row gutter={[8, 8]}>
            {images.data
              .filter((image) => image.originalname.toLowerCase().includes(search.toLowerCase()))
              .map((image) => {
                return (
                  <Col xs={12} sm={8} md={6} lg={4} xl={3} xxl={2} key={image._id}>
                    <GalleryImage
                      image={image}
                      name={image.originalname}
                      editHandler={editImageTrigger}
                      limit={modal.limit}
                      formKey={modal.formKey}
                      imageIndex={modal.index}
                      imageId={modal.id}
                      selected={selected}
                      selectHandler={selectHandler}
                      currentSelected={currentSelected}
                      //allSelected={allSelected}
                      deleteHandler={deleteOneImage}
                    />
                  </Col>
                );
              })}
          </Row>
        )}

        {/* MAIN GRID */}
        {images && images.isLoading && (
          <LoadingOutlined
            spin
            style={{
              fontSize: '3rem',
              marginTop: '5rem',
              marginLeft: '50%',
              transform: 'translateX(-50%)',
            }}
          />
        )}

        {images && !images.isLoading && images.isError && (
          <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>
        )}

        {images && !images.isLoading && !images.isError && images.data && !search && images.data.length > 0 && (
          <Row gutter={[8, 8]}>
            {images.data.map((image) => {
              return (
                <Col xs={12} sm={8} md={6} lg={4} xl={3} xxl={2} key={image._id}>
                  <GalleryImage
                    image={image}
                    name={image.originalname}
                    editHandler={editImageTrigger}
                    limit={modal.limit}
                    formKey={modal.formKey}
                    imageIndex={modal.index}
                    imageId={modal.id}
                    selected={selected}
                    selectHandler={selectHandler}
                    currentSelected={currentSelected}
                    allSelected={allSelected}
                    deleteHandler={deleteOneImage}
                  />
                </Col>
              );
            })}
          </Row>
        )}

        {images && images.data && !images.isLoading && images.data.length === 0 && (
          <h2 style={{ marginTop: '3rem', textAlign: 'center' }}>NO IMAGES</h2>
        )}
      </div>

      <Modal
        id={modal.id}
        centered
        visible={modal.visible}
        okText='SAVE'
        cancelText='CANCEL'
        formKey={modal.formKey}
        title={<strong>EDIT IMAGE</strong>}
        onCancel={() => setModal({ visible: false })}
        onOk={() => okHandle(modal.id)}
      >
        <Form className='form-horizontal' layout='vertical' form={form}>
          <Form.Item label='Meta title'>
            <Input value={title} onChange={(e) => setTitle(e.target.value)} name='title' />
          </Form.Item>

          <Form.Item label='Meta description' onChange={(e) => setDescription(e.target.value)}>
            <TextArea rows={4} name='description' value={description} />
          </Form.Item>

          <Form.Item label='Alt title' onChange={(e) => setAltTitle(e.target.value)}>
            <Input name='altTitle' value={altTitle} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Gallery;
