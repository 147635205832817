import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Input, Button, Modal, Avatar } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  SearchOutlined,
  EditFilled,
  EyeFilled,
  DeleteFilled,
} from '@ant-design/icons';
import { UserContext } from '../../App';
import EditUserModal from '../modals/EditModal';
import { SERVER_URL } from '../../config';
// import useAxios from '../../hooks/useAxios';

const UsersTable = ({ data, deleteHandler, columnKeys, title, editPath, viewPath }) => {
  const user = useContext(UserContext);
  const [userID, setUserID] = useState();
  const [showModal, setShowModal] = useState(false);
  const [deletePop, setDeletePop] = useState();
  // const [users, fetchUsers] = useAxios('', null, user.data.token, 'get');
  //moram koristiti state za izlistavanje imena jer iz nekog razloga vuce samo ime Test Concorda
  const [promptName, setPromptName] = useState();
  const [deleteUser, setDeleteUser] = useState();

  // useEffect(() => {
  //   fetchUsers(`${SERVER_URL}/users`, []);
  // }, []);

  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const onEditClick = (ID) => {
    setUserID(ID);
    setShowModal(!showModal);
  };

  const getUrl = (itemUrl) => {
    let url = itemUrl?.includes('http') ? itemUrl : SERVER_URL + itemUrl;

    return url;
  };

  const columns = columnKeys.map((item) => ({
    key: item,
    title:
      item === 'firstName'
        ? 'First name'
        : item === 'lastName'
        ? 'Last name'
        : `${item.charAt(0).toUpperCase()}${item.slice(1)}`,
    dataIndex: ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item,
    ...getColumnSearchProps(
      ['title', 'category'].includes(item) ? [item, user.language ? user.language.selected.code : ''] : item,
    ),
  }));

  columns.unshift({
    title: 'Avatar',
    render: (text, record) => (
      <div style={{ textAlign: 'center' }}>
        <Avatar size={36} src={record && record.avatar ? getUrl(record.avatar.url) : 'Logo'} />
      </div>
    ),
  });

  columns.push({
    title: 'Action',
    render: (text, record) => (
      <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
        <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
          <EditOutlined
            className='icon-unlock'
            title={`Edit ${title.toLowerCase()}`}
            style={{ textDecoration: 'none', color: 'black' }}
            onClick={() => onEditClick(record._id)}
          />
          <EditFilled
            className='icon-lock'
            title={`Edit ${title.toLowerCase()}`}
            style={{ textDecoration: 'none', color: 'black' }}
            onClick={() => onEditClick(record._id)}
          />
        </div>

        {viewPath && (
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
            <Link to={viewPath + record._id}>
              <EyeOutlined
                className='icon-unlock'
                title={`View ${title.toLowerCase()}`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
              <EyeFilled
                className='icon-lock'
                title={`View ${title.toLowerCase()}`}
                style={{ textDecoration: 'none', color: 'black' }}
              />
            </Link>
          </div>
        )}

        <div style={{ margin: '2px', padding: '4px' }} className='lock'>
          <DeleteOutlined
            className='icon-unlock'
            title={`Delete ${title.toLowerCase()}`}
            onClick={() => {
              setDeletePop(!deletePop);
              setPromptName(record.firstName);
              setDeleteUser(record._id);
            }}
          />
          <DeleteFilled
            className='icon-lock'
            title={`Delete ${title.toLowerCase()}`}
            onClick={() => {
              setDeletePop(!deletePop);
              setPromptName(record.firstName);
              setDeleteUser(record._id);
            }}
          />
          <Modal
            className='deleteModal'
            centered={true}
            visible={deletePop}
            onCancel={() => setDeletePop(!deletePop)}
            onOk={() => deleteHandler(deleteUser)}
            okText='Yes'
            cancelText='No'
          >
            <p>Are you sure you want to delete</p>
            {promptName && <p>{promptName.toUpperCase()}?</p>}
          </Modal>
        </div>
      </div>
    ),
  });

  return (
    <div>
      <Table
        size='middle'
        bordered
        dataSource={data}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 20,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
      />
      <EditUserModal userId={userID} showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default UsersTable;
