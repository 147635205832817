import Users from '../pages/users/Users';
import EditUser from '../pages/users/EditUser';
import ViewUser from '../pages/users/ViewUser';
import Data from '../pages/data/Data';
import EditData from '../pages/data/EditData';
import Categories from '../pages/data/Categories';
import Attributes from '../pages/data/Attributes';
import Gallery from '../pages/gallery/Gallery';
import GalleryNewContent from '../pages/gallery/GalleryNewContent';
import Post from '../pages/post/Post';
import Newsletter from '../pages/newsletter/Newsletter';
import EditPost from '../pages/post/EditPost';
import Tag from '../pages/tags/Tag';
import EditTag from '../pages/tags/EditTag';
import Declaration from '../pages/declaration/Declaration';
import EditDeclaration from '../pages/declaration/EditDeclaration';
// import Languages from '../pages/languages/Languages';
import DraftPost from '../pages/post/DraftPost';
import ImportData from '../pages/importData/ImportData';
import Pages from '../pages/page/Page';
import EditPages from '../pages/page/EditPage';
import Invoices from '../pages/invoices/Invoice';
import Orders from '../pages/orders/Orders';
import EditOrder from '../pages/orders/EditOrders';
import Refunds from '../pages/refund/Refunds';
import EditRefund from '../pages/refund/EditRefund';

const routes = [
  {
    label: 'Users',
    children: [
      {
        label: 'All Users',
        path: '/admin/users',
        component: Users,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'New User',
        path: '/admin/new-user',
        component: EditUser,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Edit User',
        path: '/admin/edit-user/:userId',
        component: EditUser,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'View',
        path: '/admin/view-user/:id',
        component: ViewUser,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  //View user stoji ovde samo kao ruta koja omogucava da se klikom na ikonicu profil redirectuje na profil
  //Ne dirati
  {
    label: 'Gallery',
    allowed: ['admin'],
    children: [
      {
        label: 'Gallery',
        path: '/admin/gallery',
        component: Gallery,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'New Content',
        path: '/admin/gallery/new-content',
        component: GalleryNewContent,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'Data',
    children: [
      {
        label: 'All Data',
        path: '/admin/data',
        component: Data,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'New Data',
        path: '/admin/new-data',
        component: EditData,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Edit Data',
        path: '/admin/edit-data/:dataId',
        component: EditData,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Categories',
        path: '/admin/data-categories',
        component: Categories,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Add/Edit Category',
        path: '/admin/data-categories/:categoryId',
        component: Categories,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Attributes',
        path: '/admin/data-attributes',
        component: Attributes,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'Post',
    children: [
      {
        label: 'All posts',
        path: '/admin/posts',
        component: Post,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'New post',
        path: '/admin/new-post',
        component: EditPost,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Edit post',
        path: '/admin/edit-post/:postId',
        component: EditPost,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'All DraftPosts',
        path: '/admin/draft-post/:postId',
        component: DraftPost,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'All tags',
        path: '/admin/tags',
        component: Tag,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'New tag',
        path: '/admin/new-tag',
        component: EditTag,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Edit tag',
        path: '/admin/edit-tag/:tagId',
        component: EditTag,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },

  {
    label: 'Newsletter',
    children: [
      {
        label: 'All newsletter',
        path: '/admin/newsletter',
        component: Newsletter,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },

  {
    label: 'Pages',
    children: [
      {
        label: 'All pages',
        path: '/admin/Pages',
        component: Pages,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'New page',
        path: '/admin/new-page',
        component: EditPages,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Edit page',
        path: '/admin/edit-page/:id',
        component: EditPages,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Orders',
    children: [
      {
        label: 'All orders',
        path: '/admin/orders',
        component: Orders,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Edit order',
        path: '/admin/edit-order/:orderId',
        component: EditOrder,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Invoices',
    children: [
      {
        label: 'All invoices',
        path: '/admin/invoices',
        component: Invoices,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'Refunds',
    children: [
      {
        label: 'All refunds',
        path: '/admin/refunds',
        component: Refunds,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Edit order',
        path: '/admin/edit-refunds/:refundId',
        component: EditRefund,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Declarations',
    children: [
      {
        label: 'All declarations',
        path: '/admin/declarations',
        component: Declaration,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'New declaration',
        path: '/admin/new-declaration',
        component: EditDeclaration,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Edit post',
        path: '/admin/edit-declaration/:declarationId',
        component: EditDeclaration,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Import Data',
    children: [
      {
        label: 'Import Data',
        path: '/admin/ImportData',
        component: ImportData,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  // {
  //   label: 'My profile',
  //   children: [
  //     {
  //       label: 'View',
  //       path: '/admin/view-user/:id',
  //       component: ViewUser,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //   ],
  // },

  // {
  //   label: 'Languages',
  //   icon: TranslationOutlined,
  //   children: [
  //     {
  //       label: 'Languages',
  //       path: '/admin/languages',
  //       component: Languages,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //   ],
  // },
];

export default routes;
