import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/PostTable';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = ['_id', '__v', 'content', 'user', 'email'];

const Orders = () => {
  const currentuser = useContext(UserContext);
  const [orders, fetchOrders] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    fetchOrders(`${SERVER_URL}/orders`, []);
  }, [fetchOrders]);

  const deleteOrderHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/orders/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/orders');
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  if (orders.data && orders.data.items && orders.data.items.length > 0) {
    const keys = Object.keys(orders.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
    columnKeys.splice(1, 0, 'email');
  }

  let tableData = orders.data.items;
  if (orders.data && orders.data.items && orders.data.items.length > 0) {
    tableData = orders.data.items.map((item) => {
      if (item.user) {
        item.email = item.user.email;
        item.createdAt = new Date(item.createdAt).toLocaleString();
        item.updatedAt = new Date(item.updatedAt).toLocaleString();
        return item;
      } else if (item.guestUserData) {
        item.email = item.guestUserData.email;
        item.createdAt = new Date(item.createdAt).toLocaleString();
        item.updatedAt = new Date(item.updatedAt).toLocaleString();
        return item;
      } else {
        return item;
      }
    });
  }

  return (
    <div className='table'>
      <div style={{ textAlign: 'center' }}>
        {orders.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!orders.isLoading && orders.data && orders.data.items && orders.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteOrderHandler}
            columnKeys={columnKeys}
            title='Order'
            editPath='/admin/edit-order/'
          />
        )}
        {!orders.isLoading && orders.data && orders.data.items && orders.data.items.length === 0 && <h2>NO DATA</h2>}
      </div>
    </div>
  );
};

export default Orders;
