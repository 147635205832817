import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../App';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/NewsletterTable';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = ['_id', '__v', 'description', 'image', 'user', 'url', 'tag', 'post'];

const Newsletter = () => {
  const currentuser = useContext(UserContext);
  const [newsletter, fetchnewsletter] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    fetchnewsletter(`${SERVER_URL}/newsletter`, []);
  }, [fetchnewsletter]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/newsletter/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      // history.push('/admin/posts');
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  if (newsletter.data && newsletter.data.items && newsletter.data.items.length > 0) {
    const keys = Object.keys(newsletter.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];

  if (newsletter.data && newsletter.data.items && newsletter.data.items.length > 0) {
    tableData = newsletter.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      // item.user = item.createdBy.email;
      return item;
    });

    if (tableData.email === newsletter.email) {
    }
  }
  // const email ='email'
  // const filterData =[...new Map(tableData.map(data =>[data[email],data])).values()];
  // console.log(filterData);

  return (
    <div className='table'>
      {/* <div className='actions-block'>
        <Link to='/admin/new-newsletter'>
          <Button type='primary'>New newsletter</Button>
        </Link>
      </div> */}

      <div style={{ textAlign: 'center' }}>
        {newsletter.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!newsletter.isLoading && newsletter.data && newsletter.data.items && newsletter.data.items.length > 0 && (
          <Table
            data={tableData}
            deleteHandler={deleteDataHandler}
            columnKeys={columnKeys}
            title='newsletter'
            editPath='/admin/edit-newsletter/'
          />
        )}
        {!newsletter.isLoading && newsletter.data && newsletter.data.items && newsletter.data.items.length === 0 && (
          <h2>NO DATA</h2>
        )}
      </div>
    </div>
  );
};

export default Newsletter;
